import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import Home from "../components/home/Home";
import TicketsComingSoon from "../components/client_pages/4ct/TicketsComingSoon";
import "semantic-ui-css/semantic.min.css";
import E404 from "../components/E404";
import Privacy from "../components/Privacy";
import Terms from "../components/Terms";

const Routing = () => {
  const pathname = window.location.pathname;
  return (
    <Router>
      {pathname !== "/" && /\/$/.test(pathname) && (
        <Redirect to={pathname.slice(0, -1)} />
      )}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/tickets/4ct" component={TicketsComingSoon} />
        <Route exact path="/ticketing/privacy" component={Privacy} />
        <Route exact path="/ticketing/terms" component={Terms} />
        <Route path="*">
          <E404></E404>
        </Route>
      </Switch>
    </Router>
  );
};

export default Routing;
