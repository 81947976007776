import React, { useEffect } from "react";
import logo from "./DefttGradient.png";
import "./Home.css";

function Home() {
  useEffect(() => {
    document.title = "Home - Deftt";
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Custom software, media, and payment solutions.</p>
        <a className="App-link" href="mailto:hello@deftt.io">
          hello@deftt.io
        </a>
      </header>
    </div>
  );
}

export default Home;
