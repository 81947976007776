import React from "react";
import { Container } from "semantic-ui-react";

export default function Terms() {
  return (
    <div>
      <Container
        style={{
          marginTop: "80px",
        }}
      >
        <p dir="ltr">
          <span>
            Welcome to Deftt! The following are the terms of use ("Terms") that
            govern your use of Deftt sites and mobile
            applications&mdash;including without limitation{" "}
          </span>
          <a href="http://www.deftt.io/">
            <span>www.deftt.io</span>
          </a>
          <span>, </span>
          <a href="http://www.ticketing.deftt.io/">
            <span>www.ticketing.deftt.io</span>
          </a>
          <span>, and </span>
          <a href="http://www.watch.deftt.io/">
            <span>www.watch.deftt.io</span>
          </a>
          <span>
            {" "}
            -(collectively, the "Site"), and your purchase, possession, or use
            of any Deftt tickets, products, or services.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Our Privacy Policy, Purchase Policy, and any other policies, rules
            or guidelines that may be applicable to particular offers or
            features on the Site are also incorporated into these Terms. By
            visiting or using the Site, you expressly agree to these Terms, as
            updated from time to time.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Please note that while some of the events listed on the Site may
            appeal to children, the Site is not targeted at children under the
            age of 13, and they are not permitted to use the Site. We strongly
            encourage all parents and guardians to monitor the Internet use by
            their children. If you use the Site, you affirm you are at least 13
            years old.
          </span>
        </p>
        <h2 dir="ltr">
          <span>NOTICE REGARDING FUTURE CHANGES TO TERMS:</span>
        </h2>
        <p dir="ltr">
          <span>
            We may make changes to these Terms at any time. Any changes we make
            will be effective immediately when we post a revised version of
            these Terms on the Site. The "Last Updated" date above will tell you
            when these Terms were last revised. By continuing to use this Site
            after that date, you agree to the changes.
          </span>
        </p>
        <h2 dir="ltr">
          <span>&nbsp;1. Ticket Codes</span>
        </h2>
        <p dir="ltr">
          <span>
            You may browse the Site without registering for an account. You will
            be responsible for all activities (including purchases) that are
            conducted on the Site. You may not transfer or sell access to your
            ticket code. We will not be liable for any harm related to
            disclosure of your ticket code or the use by anyone else of your
            ticket code. You may not use another user's ticket code without that
            user's permission. You will immediately notify us in writing if you
            discover any unauthorized use of your ticket code or other security
            breach. You will have no ownership in your ticket code. We may
            refuse your purchase, cancel your ticket code, or deny access to the
            Site for any reason.
          </span>
        </p>
        <h2 dir="ltr">
          <span>2. Code of Conduct</span>
        </h2>
        <p dir="ltr">
          <span>
            You agree that you will comply with all applicable laws, rules and
            regulations, and that you will not:
          </span>
        </p>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Restrict or inhibit any other person from using the Site;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>Use the Site for any unlawful purpose;</span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Express or imply that any statements you make are endorsed by
                us, without our prior written consent;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Impersonate any person or entity, whether actual or fictitious,
                including any employee or representative of our company;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Submit (a) any content or information that is unlawful,
                fraudulent, libelous, defamatory, or otherwise objectionable, or
                infringes our or any third party's intellectual property or
                other rights; (b) any non-public information about companies
                without authorization; or (c) any advertisements, solicitations,
                chain letters, pyramid schemes, surveys, contests, investment
                opportunities or other unsolicited commercial communication;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Submit, or provide links to, any postings containing material
                that could be considered harmful, obscene, pornographic,
                sexually explicit, indecent, lewd, violent, abusive, profane,
                insulting, threatening, harassing, hateful or otherwise
                objectionable, includes the image or likeness of individuals
                under 18 years of age, encourages or otherwise depicts or
                glamorizes drug use (including alcohol and cigarettes),
                characterizes violence as acceptable, glamorous or desirable, or
                contains any personal contact information or other personal
                information identifying any third party;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Submit, or provide links to, any postings containing material
                that harasses, victimizes, degrades, or intimidates an
                individual or group of individuals on the basis of religion,
                race, ethnicity, sexual orientation, gender, age, or disability;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>Engage in spamming or flooding;</span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>Harvest or collect information about Site users;</span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Order, or attempt to order, a number of tickets for an event
                that exceeds the stated limit for that event;
              </span>
            </p>
          </li>
        </ul>
        <h2 dir="ltr">
          <span>3. Ownership of Content and Grant of Conditional License</span>
        </h2>
        <p dir="ltr">
          <span>
            The Site and all data, text, designs, pages, print screens, images,
            artwork, photographs, audio and video clips, and HTML code, source
            code, or software that reside or are viewable or otherwise
            discoverable on the Site, and all tickets obtained from the Site,
            (collectively, the "Content") are owned by us or our licensors. We
            own a copyright and, in many instances, patents and other
            intellectual property in the Site and Content. We may change the
            Content and features of the Site at any time.
          </span>
        </p>
        <p dir="ltr">
          <span>
            We grant you a limited, conditional, no-cost, non-exclusive,
            non-transferable, non-sub-licensable license to view this Site and
            its Content as permitted by these Terms for non-commercial purposes
            only if, as a condition precedent, you agree that you will not:
          </span>
        </p>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Submit any software or other materials that contain any viruses,
                worms, Trojan horses, defects, date bombs, time bombs or other
                items of a destructive nature;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Manipulate identifiers, including by forging headers, in order
                to disguise the origin of any posting that you submit;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Link to any portion of the Site other than the URL assigned to
                the home page of the Site;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>"Frame" or "mirror" any part of the Site;</span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Modify, adapt, sub-license, translate, sell, reverse engineer,
                decompile or disassemble any portion of the Site, or otherwise
                attempt to derive any source code or underlying ideas or
                algorithms of any part of the Content;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Remove any copyright, trademark or other proprietary rights
                notices contained on the Site;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Use any computer program, bot, robot, spider, offline reader,
                site search/retrieval application or other manual or automatic
                device, tool, or process to retrieve, index, data mine, or in
                any way reproduce or circumvent the security structure,
                navigational structure, or presentation of the Content or the
                Site, including with respect to any CAPTCHA displayed on the
                Site. Operators of public search engines may use spiders to copy
                materials from the Site for the sole purpose of and solely to
                the extent necessary for creating publicly available searchable
                indices of the materials, but not caches or archives of such
                materials. We may revoke this exception at any time and require
                removal of archived materials gathered in the past;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Use any automated software or computer system to search for,
                reserve, buy or otherwise obtain tickets, discount codes,
                promotional codes, vouchers, credits, gift cards or any other
                items available on the Site, including sending information from
                your computer to another computer where such software or system
                is active;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Take any action that imposes or may impose (in our sole
                discretion) an unreasonable or disproportionately large load on
                our infrastructure;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Access, reload, or refresh transactional event or ticketing
                pages, or make any other request to transactional servers, more
                than once during any three-second interval;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Request more than 1,000 pages of the Site in any 24-hour period,
                whether alone or with a group of individuals;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Make more than 800 reserve requests on the Site in any 24-hour
                period, whether alone or with a group of individuals;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Reproduce, modify, display, publicly perform, distribute or
                create derivative works of the Site or the Content;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Reproduce or scan tickets in a format or medium different from
                that provided by the Site;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Decode, decrypt, modify, or reverse engineer any tickets or
                underlying algorithms or barcodes used on or in production of
                tickets or the Site;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Use the Site or the Content in an attempt to, or in conjunction
                with, any device, program or service designed to circumvent any
                technological measure that effectively controls access to, or
                the rights in, the Site and/or Content in any way including,
                without limitation, by manual or automatic device or process,
                for any purpose;
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Use ticket bot technology to search for, reserve, or purchase
                tickets through the Site; for the avoidance of doubt, this
                specifically prohibits you from using automated ticket
                purchasing software on the Site, and prohibits you from
                circumventing any security measure, access control system, or
                other technological control or measure on the Site that is used
                to enforce posted event ticket purchasing limits or to maintain
                the integrity of posted online ticket purchasing order rules.
              </span>
            </p>
          </li>
        </ul>
        <p dir="ltr">
          <span>
            This license is expressly conditioned on your preexisting agreement
            to comply with, and your actual compliance with, each of the
            provisions described in this Ownership of Content and Grant of
            Conditional License section. This license exists only so long as you
            strictly comply with each of the provisions described in this
            section. Any use of the Site or Content by you or anyone acting on
            your behalf that does not strictly comply with each and every
            provision in this section exceeds the scope of the license granted
            to you herein, constitutes unauthorized reproduction, display, or
            creation of unauthorized derivative versions of the Site and
            Content, and infringes our copyrights, trademarks, patents and other
            rights in the Site and Content. You will not acquire any ownership
            rights by using the Site or the Content.
          </span>
        </p>
        <p dir="ltr">
          <span>
            The registered and unregistered trademarks, logos, and service marks
            displayed on the Site are owned by us or our licensors. You may not
            use our trademarks, logos, and service marks in any way without our
            prior written permission. You may inquire about obtaining permission
            by contacting us at ticketing@deftt.io
          </span>
        </p>
        <h2 dir="ltr">
          <span>4. Making Purchases</span>
        </h2>
        <p dir="ltr">
          <span>
            Please review our Purchase Policy, which (in addition to these
            Terms) will govern your purchase of any tickets or other products
            through the Site, including any refunds or exchanges. We may impose
            conditions on your use of any coupon, promotional code, credit, or
            gift card. You will pay all charges incurred by you or any users of
            your account and credit card (or other applicable payment mechanism)
            at the price(s) in effect when such charges are incurred, including
            any applicable taxes. You may only use credit or debit cards, gift
            cards or vouchers that belong to you or to people who expressly
            authorize you to use such payment methods.&nbsp;
          </span>
        </p>
        <p dir="ltr">
          <span>
            You may not attempt to conceal your identity by using multiple
            Internet Protocol addresses or email addresses, or by any other
            means, to conduct transactions on the Site.
          </span>
        </p>
        <p dir="ltr">
          <span>
            You will not hold us liable if you do not comply with laws related
            to your transactions. We may provide law enforcement with
            information you provide to us related to your transactions to assist
            in any investigation or prosecution of you.
          </span>
        </p>
        <p dir="ltr">
          <span>
            If we are unable to verify or authenticate any information or
            tickets you provide during any registration, ordering, purchase,
            ticket posting, sale, authentication, delivery, payment or
            remittance process, or any other process, or if we are no longer
            able to verify or authorize your credit card or bank account
            information, your tickets may be cancelled, we may refuse to honor
            all pending and future ticket purchases made with those credit card
            or bank accounts and/or via any online accounts associated with
            those credit card or bank accounts. We may also prohibit you from
            using the Site.
          </span>
        </p>
        <p dir="ltr">
          <span>
            You will not use ticket bot technology to search for, reserve, or
            purchase tickets through the Site; for the avoidance of doubt, this
            specifically prohibits you from using automated ticket purchasing
            software on the Site, and prohibits you from circumventing any
            security measure, access control system, or other technological
            control or measure on the Site that is used to enforce posted event
            ticket purchasing limits or to maintain the integrity of posted
            online ticket purchasing order rules.
          </span>
        </p>
        <h2 dir="ltr">
          <span>5. Claims of Copyright Infringement on the Site</span>
        </h2>
        <p dir="ltr">
          <span>
            Under the Digital Millennium Copyright Act of 1998 (the "DMCA") if
            you believe in good faith that any content on the Site infringes
            your copyright, you may send us a notice requesting that the content
            be removed. The notice must include: (a) your (or your agent's)
            physical or electronic signature; (b) identification of the
            copyrighted work on our Site that is claimed to have been infringed
            (or a representative list if multiple copyrighted works are included
            in one notification); (c) identification of the content that is
            claimed to be infringing or the subject of infringing activity,
            including information reasonably sufficient to allow us to locate
            the content on the Site; (d) your name, address, telephone number
            and email address (if available); (e) a statement that you have a
            good faith belief that use of the content in the manner complained
            of is not authorized by you or your agent or the law; and (f) a
            statement that the information in the notification is accurate and,
            under penalty of perjury, that you or your agent is authorized to
            act on behalf of the copyright owner. If you believe in good faith
            that a notice of copyright infringement has been wrongly filed
            against you, you may send us a counter-notice. You may read more
            information about the DMCA at{" "}
          </span>
          <a href="http://www.loc.gov/copyright">
            <span>http://www.loc.gov/copyright</span>
          </a>
          <span>.</span>
        </p>
        <p dir="ltr">
          <span>
            Notices and counter-notices should be sent to ticketing@deftt.io.
            There can be penalties for false claims under the DMCA. We suggest
            that you consult your legal advisor before filing a notice or
            counter-notice.
          </span>
        </p>
        <p dir="ltr">
          <span>
            It is our policy to terminate, in appropriate circumstances, the
            access rights to the Site of repeat infringers.
          </span>
        </p>
        <h2 dir="ltr">
          <span>6. Links</span>
        </h2>
        <p dir="ltr">
          <span>
            The Site contains links to other websites that may not be owned or
            operated by us. The fact that we may link to those websites does not
            indicate any approval or endorsement of those websites. We have no
            control over those websites. We are not responsible for the content
            of those websites, or the privacy practices of those websites. We
            strongly encourage you to become familiar with the terms of use and
            practices of any linked website. Your use of other websites is at
            your own risk, and is subject to the terms of those websites. It is
            up to you to take precautions to ensure that whatever links you
            select or software you download (whether from the Site or other
            sites) is free of viruses, worms, Trojan horses, defects, date
            bombs, time bombs, and other items of a destructive nature.
          </span>
        </p>
        <h2 dir="ltr">
          <span>7. Parental Controls</span>
        </h2>
        <p dir="ltr">
          <span>
            We cannot prohibit minors from visiting our Site and must rely on
            parents and guardians to decide what materials are appropriate for
            children to view and purchase. There are parental control
            protections (such as computer hardware, software or filtering
            services) available that may assist you in limiting access to
            material that is harmful to minors. You can find information about
            parental controls at{" "}
          </span>
          <a href="http://onguardonline.gov/">
            <span>http://onguardonline.gov</span>
          </a>
          <span>
            . We do not endorse the products or services listed at this website.
          </span>
        </p>
        <h2 dir="ltr">
          <span>8. Access from Outside the United States</span>
        </h2>
        <p dir="ltr">
          <span>
            The Site is directed to people residing in the United States. We do
            not represent that Content available on or through the Site is
            appropriate or available in other locations. We may limit the
            availability of the Site or any service or product described on the
            Site to any person or geographic area at any time. If you choose to
            access the Site from outside the United States, you do so at your
            own risk.
          </span>
        </p>
        <h2 dir="ltr">
          <span>9. Mobile Messaging</span>
        </h2>
        <p dir="ltr">
          <span>
            We offer browsing and mobile messaging services which may include
            alerts, Promotions, and offers for products. You may choose to
            receive mobile alerts by entering your phone number. If you do, you
            authorize us to use automated technology to send messages to the
            mobile phone number you supply. Your consent to receive mobile
            communications is never required in order to purchase something from
            us.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Message and data rates may apply, according to your rate plan
            provided by your wireless carrier. We will not be responsible for
            any text messaging or other wireless charges incurred by you or by a
            person who has access to your wireless device or telephone number.
            You may not receive our alerts if your carrier does not permit text
            alerts. Your carrier may not allow you to use pre-paid phones or
            calling plans to receive alerts. We may send you a bounce back
            message for every message you send to us. Service may not be
            compatible with all wireless carriers or devices.
          </span>
        </p>
        <p dir="ltr">
          <span>
            You may opt out of any alerts by replying to an alert with the text
            message "STOP" or by sending the text message "STOP" to the phone
            number provided. If you opt out by sending us a text message, we
            will send you a text to confirm your request. If you do not want to
            receive a confirmation text message, you may opt out by sending an
            email to{" "}
          </span>
          <a>
            <span>ticketing@deftt.io</span>
          </a>
          <span>
            {" "}
            with your request and mobile device number. It may take us up to 10
            days to remove your mobile device number from our database.&nbsp;
          </span>
        </p>
        <p dir="ltr">
          <span>
            You authorize your wireless carrier to disclose information about
            your account, such as subscriber status, payment method and device
            details, if available, to support identity verification, fraud
            avoidance and other uses in support of transactions for the duration
            of your business relationship with us. Information may also be
            shared with other service providers to further support identity
            verification and fraud prevention.
          </span>
        </p>
        <p dir="ltr">
          <span>
            We are not responsible for the accuracy of any information displayed
            in our mobile messaging, for any misdelivery or untimely delivery of
            any mobile messaging, or your deletion or failure to store any
            mobile messaging from us.
          </span>
        </p>
        <h2 dir="ltr">
          <span>10. Violation of these Terms</span>
        </h2>
        <p dir="ltr">
          <span>
            We may investigate any violation of these Terms, including
            unauthorized use of the Site. We may provide law enforcement with
            information you provide to us related to your transactions to assist
            in any investigation or prosecution of you. We may take legal action
            that we feel is appropriate. You agree that monetary damages may not
            provide us a sufficient remedy, and that we may pursue injunctive or
            other relief for your violation of these Terms. If we determine that
            you have violated these Terms or the law, or for any other reason or
            for no reason, we may cancel your account, delete all your User
            Content, and prevent you from accessing the Site at any time without
            notice to you. If that happens, you may no longer use the Site or
            any Content. You will still be bound by your obligations under these
            Terms. You agree that we will not be liable to you or any third
            party for termination of your access to the Site or to your account
            or any related information, and we will not be required to make the
            Site or your account or any related information available to you. We
            may also cancel any ticket or merchandise order, and tickets or
            merchandise acquired through your order. We may refuse to honor
            pending and future purchases made from all accounts we believe may
            be associated with you, or cancel a ticket or ticket order
            associated with any person we believe to be acting with you, or
            cancel your ticket postings, or exercise any other remedy available
            to us.
          </span>
        </p>
        <p dir="ltr">
          <span>
            You agree that your abusive use of the Site may cause damage and
            harm to us, including impaired goodwill, lost sales, and increased
            expenses. You also agree that monetary damages for your abusive use
            of the Site are difficult to determine, and that if you, or others
            acting with you, request more than 1,000 pages of the Site or make
            more than 800 reserve requests on the Site in any 24-hour period,
            you, and those acting with you, will be jointly and severally liable
            for liquidated damages in the amount of twenty-five cents ($0.25)
            for each page request or reserve request made during that 24-hour
            period which exceeds those limits.
          </span>
        </p>
        <h2 dir="ltr">
          <span>11. Disclaimer of Warranties</span>
        </h2>
        <p dir="ltr">
          <span>
            WE PROVIDE THE SITE AND THE CONTENT TO YOU "AS IS" AND "AS
            AVAILABLE." WE TRY TO KEEP THE SITE UP, BUG-FREE, AND SAFE, BUT YOU
            USE IT AT YOUR OWN RISK. TO THE FULLEST EXTENT PERMISSIBLE BY LAW,
            AND TO THE EXTENT THAT APPLICABLE LAW PERMITS THE DISCLAIMER OF
            EXPRESS OR IMPLIED WARRANTIES, WE DISCLAIM ALL WARRANTIES, EXPRESS
            OR IMPLIED, INCLUDING ANY WARRANTY OF TITLE, NON-INFRINGEMENT,
            ACCURACY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
            WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING OR COURSE OF
            PERFORMANCE OR USAGE OF TRADE. WE DO NOT GUARANTEE THAT THE SITE
            WILL ALWAYS BE SAFE, SECURE, OR ERROR-FREE, OR THAT THE SITE WILL
            ALWAYS FUNCTION WITHOUT DISRUPTIONS, DELAYS, OR IMPERFECTIONS. WE
            ARE NOT RESPONSIBLE FOR THE ACTIONS OR INFORMATION OF THIRD PARTIES,
            AND YOU RELEASE US FROM ANY CLAIMS AND DAMAGES, KNOWN AND UNKNOWN,
            ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE
            AGAINST ANY SUCH THIRD PARTIES. IF YOU ARE A CALIFORNIA RESIDENT,
            YOU WAIVE CALIFORNIA CIVIL CODE &sect;1542, WHICH SAYS: A GENERAL
            RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW
            OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE
            RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED
            HIS SETTLEMENT WITH THE DEBTOR.
          </span>
        </p>
        <h2 dir="ltr">
          <span>12. Limitation of Liability</span>
        </h2>
        <p dir="ltr">
          <span>
            IN NO EVENT WILL WE OR OUR EVENT ORGANIZERS, SUPPLIERS, ADVERTISERS
            AND SPONSORS, BE RESPONSIBLE OR LIABLE TO YOU OR ANYONE ELSE FOR,
            AND YOU HEREBY KNOWINGLY AND EXPRESSLY WAIVE ALL RIGHTS TO SEEK,
            DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OF
            ANY TYPE, AND ANY RIGHTS TO HAVE DAMAGES MULTIPLIED OR OTHERWISE
            INCREASED, ARISING OUT OF OR IN CONNECTION WITH THE SITE, THE
            CONTENT, OR ANY PRODUCT OR SERVICE PURCHASED THROUGH THE SITE, EVEN
            IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND
            REGARDLESS OF WHETHER THE CLAIM IS BASED UPON ANY CONTRACT, TORT, OR
            OTHER LEGAL OR EQUITABLE THEORY. WITHOUT LIMITING THE FOREGOING, YOU
            EXPRESSLY ACKNOWLEDGE AND AGREE THAT WE WILL HAVE NO LIABILITY OR
            RESPONSIBILITY WHATSOEVER FOR (a) ANY FAILURE OF ANOTHER USER OF THE
            SITE TO CONFORM TO THE CODES OF CONDUCT, (b) PERSONAL INJURY OR
            PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, WHETHER ARISING IN
            CONTRACT OR IN TORT, RESULTING FROM YOUR ACCESS TO AND USE OF OUR
            SITE, (c) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
            AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
            STORED THEREIN, (d) ANY BUGS, VIRUSES, WORMS, TROJAN HORSES,
            DEFECTS, DATE BOMBS, TIME BOMBS OR OTHER ITEMS OF A DESTRUCTIVE
            NATURE WHICH MAY BE TRANSMITTED TO OR THROUGH OUR SITE, (e) ANY
            ERRORS, MISTAKES, INACCURACIES OR OMISSIONS IN ANY CONTENT, OR (f)
            ANY LOST, STOLEN OR DAMAGED TICKETS, OR THE FAILURE OF A VENUE TO
            HONOR A TICKET. YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION
            WITH THE SITE IS TO STOP USING THE SITE. THE LIMITATIONS IN THIS
            SECTION WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL
            PURPOSE. THE ALLOCATION OF RISK BETWEEN US IS AN ESSENTIAL ELEMENT
            OF THE BASIS OF THE BARGAIN BETWEEN US. OUR AGGREGATE LIABILITY
            ARISING OUT OF THESE TERMS OR THE USE OF THE SITE WILL NOT EXCEED
            THE GREATER OF ONE HUNDRED DOLLARS ($100) OR THE AMOUNT YOU HAVE
            PAID US IN THE PAST TWELVE MONTHS. IN NO EVENT WILL ATTORNEYS' FEES
            BE AWARDED OR RECOVERABLE. OUR LIABILITY WILL BE LIMITED UNDER THIS
            PARAGRAPH TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, AND THE
            PROVISIONS OF THIS PARAGRAPH WILL NOT APPLY TO THE EXTENT APPLICABLE
            LAW PERMITS THE RECOVERY OF DAMAGES, ATTORNEYS' FEES OR COSTS
            OTHERWISE PROHIBITED UNDER THIS PARAGRAPH. THE PROVISIONS OF THIS
            PARAGRAPH THAT (A) PROHIBIT DAMAGES TO BE MULTIPLIED OR OTHERWISE
            INCREASED, (B) IMPOSE A DAMAGES LIMITATION OF THE GREATER OF ONE
            HUNDRED DOLLARS ($100) OR THE AMOUNT YOU HAVE PAID US IN THE PAST
            TWELVE MONTHS, AND (C) PROHIBIT THE RECOVERY OF ATTORNEYS' FEES AND
            COSTS, DO NOT APPLY IN CERTAIN STATES, INCLUDING WITHOUT LIMITATION
            NEW JERSEY, TO CLAIMS BROUGHT UNDER STATUTES PERMITTING SUCH
            RECOVERY.
          </span>
        </p>
        <h2 dir="ltr">
          <span>13. Indemnification</span>
        </h2>
        <p dir="ltr">
          <span>
            If anyone brings a claim against us related to your use of the Site,
            the Content, your User Content or your violation of these Terms, you
            agree to indemnify, defend and hold us and our affiliated companies,
            Event Organizers, suppliers, advertisers and sponsors, and each of
            our officers, directors, employees, and agents, harmless from and
            against any and all claims, damages, losses and expenses of any kind
            (including reasonable legal fees and costs). We reserve the right to
            take exclusive control and defense of any claim, and you will
            cooperate fully with us in asserting any available defenses.
          </span>
        </p>
        <h2 dir="ltr">
          <span>14. Severability</span>
        </h2>
        <p dir="ltr">
          <span>
            It is our belief that these Terms do not contain any provision
            contrary to law. However, if any part of these Terms is determined
            to be illegal, invalid, or unenforceable, you agree that: (a) that
            part shall nevertheless be enforced to the extent permissible in
            order to effect the intent of these Terms, and (b) the remaining
            parts shall be deemed valid and enforceable.
          </span>
        </p>
        <h2 dir="ltr">
          <span>15. Questions</span>
        </h2>
        <p dir="ltr">
          <span>
            If you have any questions, comments or complaints regarding these
            Terms or the Site, please contact us at:
          </span>
        </p>
        <p dir="ltr">
          <span>ticketing@deftt.io</span>
        </p>
      </Container>
      <br></br>
      <br></br>

      <br></br>
    </div>
  );
}
