import React from "react";
import { Segment, Container, Image } from "semantic-ui-react";

export default function E404() {
  var today = new Date();
  return (
    <div>
      <Container style={{ width: "50%" }}>
        <Segment.Group style={{ marginTop: "30vh" }}>
          <Segment attached style={{ textAlign: "center" }}>
            <h2>Error 404</h2>
          </Segment>
          <Segment attached>
            <Segment.Group>
              <Segment style={{ textAlign: "center" }}>
                <h4 style={{ marginBottom: "2px" }}>
                  This page does not exist.
                </h4>

                <p>
                  <i>Please check the URL entered and try again.</i>
                </p>
              </Segment>
            </Segment.Group>

            {/* <Button fluid>Continue</Button> */}
          </Segment>
        </Segment.Group>
        <br></br>
        <div style={{ textAlign: "center" }}>
          <p style={{ marginBottom: "5px" }}>Powered by:</p>
          <Image
            src="/Deftt_Black.png"
            href="https://deftt.io"
            size="small"
            centered
          ></Image>
          <p style={{ marginTop: "5px" }}>
            Copyright &copy; {today.getFullYear()} |{" "}
            <a href="https://deftt.io">Deftt, LLC</a>
          </p>
          <br></br>
        </div>
      </Container>
    </div>
  );
}
