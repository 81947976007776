import React, { useEffect } from "react";
import {
  Segment,
  Container,
  Grid,
  Image,
  Button,
  Form,
  Input,
} from "semantic-ui-react";
import logo from "./Deftt_Black.png";

export default function TicketsComingSoon() {
  window.location.href = "https://watch.deftt.io/event/51783";
  useEffect(() => {
    document.title = "4 Chairs Theatre - Deftt Ticketing";
  }, []);
  const today = new Date();

  return null;
  return (
    <div>
      <Container style={{ width: "50%" }}>
        <Segment style={{ marginTop: "10vh" }}>
          <Grid column={2}>
            <Grid.Column width={6}>
              <Image
                src="https://yt3.ggpht.com/ytc/AAUvwngbqm8eYIKwkXdOnausm6wfIeKblWp_e3zvZLIq=s176-c-k-c0x00ffffff-no-rj"
                circular
                size="small"
                style={{ margin: "25px" }}
              ></Image>
            </Grid.Column>
            <Grid.Column width={10} verticalAlign="middle" textAlign="center">
              <h1
                style={{
                  fontSize: "5vmin",
                  marginBottom: "0px",
                }}
              >
                4 Chairs Theatre
              </h1>
              <p style={{ fontSize: "4vmin" }}>Island Song</p>
            </Grid.Column>
          </Grid>
        </Segment>
        {/* <Segment.Group style={{ textAlign: "center" }}>
          <Segment attached>
            <h2>Background</h2>
          </Segment>
          <Segment attached>
            <p
              style={{
                fontSize: "20px",
              }}
            >
              Additional audition information here
            </p>
          </Segment>
        </Segment.Group> */}
        <Segment.Group style={{ textAlign: "center" }}>
          <Segment attached>
            <h1 style={{ marginBottom: 0 }}>You're early!</h1>
            <h4 style={{ marginTop: "10px" }}>
              Tickets for this event are not yet available. <br></br>Please
              check back here on <u>Saturday, April 17th at 12pm CDT</u>
            </h4>
          </Segment>
          <Segment attached>
            <p style={{ marginBottom: "0px" }}>Ticket Price:</p>
            <h1 style={{ marginBottom: "0px", marginTop: "0px" }}>$15.00</h1>
            <h4 style={{ marginTop: "0px", color: "gray" }}>
              + processing fee
            </h4>
          </Segment>
          <Segment attached>
            <h4>
              <b style={{ marginTop: "0px", marginBottom: "0px" }}>
                Enter your email below if you'd like to be notified when tickets
                go on sale:
              </b>
            </h4>
          </Segment>
          <Segment attached>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSf4segB_oGF_Xv5g4fnWjZxmU2TxcwZYYKjWSI6XSswAfDaTQ/viewform"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              width="100%"
              height="1000"
              scrolling="yes"
            >
              Loading…
            </iframe>
          </Segment>
          <Segment attached>
            <p>
              <i>
                Deftt, LLC does not store or process any content entered into
                this form.
              </i>
            </p>
          </Segment>
          {/* <Segment attached>
            <p>
              <b style={{ marginTop: "0px", marginBottom: "0px" }}>
                Please enter your email below if you'd like to get notified when
                tickets go on sale.
              </b>
            </p>

            <Form action="https://docs.google.com/forms/u/1/d/e/1FAIpQLSeqMG0u4JNtCpsoW0w0lRMvXseGNjKF8gGwPHB4rFXFWXQShA/formResponse">
              <Grid centered columns={4}>
                <Grid.Row style={{ marginBottom: "-20px" }}>
                  <Grid.Column width={7}>
                    <Form.Input
                      placeholder="Email address"
                      name="YPqjbf"
                      size="large"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={7}>
                    <Form.Button content="Submit" fluid size="large" />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
            <br></br>
            <p>
              <i>
                By submitting this form you allow Deftt to share your email with
                <br></br>4 Chairs Theatre to perform this one-time
                communication.
              </i>
            </p>
          </Segment> */}
        </Segment.Group>

        <br></br>
        <div style={{ textAlign: "center" }}>
          <p style={{ marginBottom: "5px" }}>Powered by:</p>
          <Image
            src={logo}
            href="https://deftt.io"
            size="small"
            centered
          ></Image>
          <p style={{ marginTop: "5px" }}>
            Copyright &copy; {today.getFullYear()} |{" "}
            <a href="https://deftt.io">Deftt, LLC</a>
          </p>
          <br></br>
        </div>
      </Container>
    </div>
  );
}
