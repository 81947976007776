import React from "react";
import { Container } from "semantic-ui-react";

export default function Privacy() {
  return (
    <div>
      <Container style={{ marginTop: "80px" }}>
        <h1 dir="ltr">
          <span>Privacy Policy for Deftt Ticketing and Deftt Streaming</span>
        </h1>
        <p dir="ltr">
          <span>
            At Deftt Ticketing, accessible from www.ticketing.deftt.io and
            www.watch.deftt.io, one of our main priorities is the privacy of our
            visitors. This Privacy Policy document contains types of information
            that is collected and recorded by Deftt Ticketing and how we use it.
          </span>
        </p>
        <p dir="ltr">
          <span>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us at
            ticketing@deftt.io.
          </span>
        </p>
        <p dir="ltr">
          <span>
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in Deftt Ticketing. This policy is
            not applicable to any information collected offline or via channels
            other than this website.
          </span>
        </p>
        <h2 dir="ltr">
          <span>Consent</span>
        </h2>
        <p dir="ltr">
          <span>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </span>
        </p>
        <h2 dir="ltr">
          <span>Information we collect</span>
        </h2>
        <p dir="ltr">
          <span>
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </span>
        </p>
        <p dir="ltr">
          <span>
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </span>
        </p>
        <p dir="ltr">
          <span>
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </span>
        </p>
        <h2 dir="ltr">
          <span>How we use your information</span>
        </h2>
        <p dir="ltr">
          <span>
            We use the information we collect in various ways, including to:
          </span>
        </p>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              <span>Provide, operate, and maintain our website</span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>Improve, personalize, and expand our website</span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>Understand and analyze how you use our website</span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Develop new products, services, features, and functionality
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>
                Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the website, and for
                marketing and promotional purposes
              </span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>Send you emails</span>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <span>Find and prevent fraud</span>
            </p>
          </li>
        </ul>
        <h2 dir="ltr">
          <span>Log Files</span>
        </h2>
        <p dir="ltr">
          <span>
            Deftt Ticketing follows a standard procedure of using log files.
            These files log visitors when they visit websites. All hosting
            companies do this and a part of hosting services' analytics. The
            information collected by log files include internet protocol (IP)
            addresses, browser type, Internet Service Provider (ISP), date and
            time stamp, referring/exit pages, and possibly the number of clicks.
            These are not linked to any information that is personally
            identifiable. The purpose of the information is for analyzing
            trends, administering the site, tracking users' movement on the
            website, and gathering demographic information.
          </span>
        </p>
        <h2 dir="ltr">
          <span>Cookies and Web Beacons</span>
        </h2>
        <p dir="ltr">
          <span>
            Like any other website, Deftt Ticketing uses 'cookies'. These
            cookies are used to store information including visitors'
            preferences, and the pages on the website that the visitor accessed
            or visited. The information is used to optimize the users'
            experience by customizing our web page content based on visitors'
            browser type and/or other information.
          </span>
        </p>
        <p dir="ltr">
          <span>For more general information on cookies, please read </span>
          <a href="https://www.privacypolicyonline.com/what-are-cookies/">
            <span>"What Are Cookies" from Cookie Consent</span>
          </a>
          <span>.</span>
        </p>
        <h2 dir="ltr">
          <span>Advertising Partners Privacy Policies</span>
        </h2>
        <p dir="ltr">
          <span>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of Deftt Ticketing.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Third-party ad servers or ad networks uses technologies like
            cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that appear on Deftt Ticketing,
            which are sent directly to users' browser. They automatically
            receive your IP address when this occurs. These technologies are
            used to measure the effectiveness of their advertising campaigns
            and/or to personalize the advertising content that you see on
            websites that you visit.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Note that Deftt Ticketing has no access to or control over these
            cookies that are used by third-party advertisers.
          </span>
        </p>
        <h2 dir="ltr">
          <span>Third Party Privacy Policies</span>
        </h2>
        <p dir="ltr">
          <span>
            Deftt's Privacy Policy does not apply to other advertisers or
            websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options.&nbsp;
          </span>
        </p>
        <p dir="ltr">
          <span>
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites.
          </span>
        </p>
        <h2 dir="ltr">
          <span>CCPA Privacy Rights (Do Not Sell My Personal Information)</span>
        </h2>
        <p dir="ltr">
          <span>
            Under the CCPA, among other rights, California consumers have the
            right to:
          </span>
        </p>
        <p dir="ltr">
          <span>
            Request that a business that collects a consumer's personal data
            disclose the categories and specific pieces of personal data that a
            business has collected about consumers.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Request that a business delete any personal data about the consumer
            that a business has collected.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Request that a business that sells a consumer's personal data, not
            sell the consumer's personal data.
          </span>
        </p>
        <p dir="ltr">
          <span>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </span>
        </p>
        <h2 dir="ltr">
          <span>GDPR Data Protection Rights</span>
        </h2>
        <p dir="ltr">
          <span>
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following:
          </span>
        </p>
        <p dir="ltr">
          <span>
            The right to access &ndash; You have the right to request copies of
            your personal data. We may charge you a small fee for this service.
          </span>
        </p>
        <p dir="ltr">
          <span>
            The right to rectification &ndash; You have the right to request
            that we correct any information you believe is inaccurate. You also
            have the right to request that we complete the information you
            believe is incomplete.
          </span>
        </p>
        <p dir="ltr">
          <span>
            The right to erasure &ndash; You have the right to request that we
            erase your personal data, under certain conditions.
          </span>
        </p>
        <p dir="ltr">
          <span>
            The right to restrict processing &ndash; You have the right to
            request that we restrict the processing of your personal data, under
            certain conditions.
          </span>
        </p>
        <p dir="ltr">
          <span>
            The right to object to processing &ndash; You have the right to
            object to our processing of your personal data, under certain
            conditions.
          </span>
        </p>
        <p dir="ltr">
          <span>
            The right to data portability &ndash; You have the right to request
            that we transfer the data that we have collected to another
            organization, or directly to you, under certain conditions.
          </span>
        </p>
        <p dir="ltr">
          <span>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </span>
        </p>
        <h2 dir="ltr">
          <span>Children's Information</span>
        </h2>
        <p dir="ltr">
          <span>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Deftt Ticketing does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </span>
        </p>
      </Container>
      <br></br>
      <br></br>

      <br></br>
    </div>
  );
}
